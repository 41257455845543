html {
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
  html {
	scroll-behavior: auto;
  }
}
.mywpglossary-list-entry-description {
  display: none;
}
.mywpglossary-letters {
  display: flex;
  flex-wrap: wrap;
}
.mywpglossary-letter {
  margin-right: 1.25rem;
}
.mywpglossary-letter:last-child {
  margin-right: 0;
}
.mywpglossary-letters span {
  opacity: .5;
}
.mywpglossary-list-letter {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.mywpglossary-letter-content {
  display: flex;
  flex-direction: column;
}
.mywpglossary-list-entry-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mywpglossary-list-entry-title svg {
  transition: all .3s ease-out .07s;
}
.mywpglossary-list-entry-title.show_parent svg {
  transform: rotate(180deg);
  transition: all .3s ease-out .07s;
}
.mywpglossary-list-entry-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mywpglossary-list-entry {
  cursor: pointer;
}

/* Pop in display */
.mywpglossary-popin {
  position: relative;
  cursor:pointer;
  text-decoration:underline;
  @media (max-width: 767px) {
    position: unset;
  }
}
.mywpglossary-popin .mywpglossary-content {
  visibility: hidden;
  width: 450px;
  background-color: black;
  font-size: medium;
  color: #fff;
  margin-top: 25px;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  @media (max-width: 767px) {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    margin: 0;
    z-index: 99999;
  }
  > *:last-child{
    margin-bottom: 0;
  }
}
.mywpglossary-popin:hover .mywpglossary-content {
  visibility: visible;
}
.mywpglossary-popin .mywpglossary-close {
  display: none;
  width: 100%;
  text-align: right;
  @media (max-width: 767px) {
    display: inline-block;
  }
}
.mywpglossary-popin .mywpglossary-close svg {
  display: none;
  width: 30px;
  height: 30px;
  fill: #ffffff;
  @media (max-width: 767px) {
    display: inline-block;
  }
}
